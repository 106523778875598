.inline {
  background-color: transparent;
  max-width: 100px;
  text-align: right;
  border: 0;
  padding: 4px;
  font-size: 14px;
}

.name {
  max-width: 300px;
  min-width: 300px;
  text-align: left;
  font-size: 16px;
  font-weight: bolder;
}

input:hover {
  background-color: #d3d3d3;
  cursor: pointer;
}
