.img {
  border-radius: 3px;
  width: 200px;
  height: 150px;
  object-fit: cover;
}

.paper {
  touch-action: none;
}

.delete {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(255, 255, 255);
  display: none;
}

.order {
  position: absolute;
  top: 5px;
  left: 5px;
  color: rgb(255, 255, 255);
  border-style: solid;
  border-radius: 22px;
  border-width: 1px;
  width: 22px;
  font-size: small;
  text-align: center;
}

.cover {
  position: absolute;
  top: 20px;
  left: 5px;
  color: rgb(255, 255, 255);
}

.paper:hover .delete {
  display: block;
}
