@tailwind base;
@tailwind components;
@tailwind utilities;


.btn {
  @apply bg-gray-500 mt-6 hover:bg-gray-400 text-white p-2 rounded-md cursor-pointer;
}

.checkbox-public {
  @apply appearance-none h-4 w-4 rounded border border-gray-300 text-gray-500 focus:ring-gray-500 checked:bg-red-500;
}

@layer base {
  @font-face {
    font-family: 'man_light';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../public/fonts/man_icon_woff2.woff2) format('woff2');
  }
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main {
  background-color: rgb(240, 240, 240);
  background-repeat: repeat;
}
