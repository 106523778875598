/* main {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

main {
  /*font-family: "PT Sans", Helvetica, Arial, sans-serif;*/
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.02em;
  color: #302f2f;
  background-color: #f1f1f1;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}

a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  outline: none;
}

a[href*="callto"],
a[href*="mailto"] {
  white-space: nowrap;
}

.link-hover {
  color: inherit;
}

.link-press {
  color: inherit;
}

.p a:hover,
.list a:hover {
  text-decoration: none;
}

.ad {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid lightgrey;
  padding: 10px;
  background-color: white;
  gap: 20px;
  position: relative;
  margin: 10px 0 10px 0;
}

.gallery_container {
  width: 280px;
}

.gallery {
  width: 100%;
  height: 100%;
  aspect-ratio: 4/3;
  position: relative;
}

.text {
  width: 500px;
}

.title {
  font-weight: lighter;
  font-size: 20px;
}

.subtitle {
  font-weight: lighter;
  color: grey;
}

.sticker {
  width: fit-content;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 5px;
  margin-top: 5px;
  top: 0;
  border-radius: 5px;
}

.desc {
  margin-top: 10px;
  font-weight: light;
  font-size: 14px;
  white-space: pre-line;
  height: 60px;
  width: 100%;
  overflow: hidden;
  color: grey;
  -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
}

.ad_button {
  padding: 8px 12px;
  background-color: white;
  width: fit-content;
  border: 2px solid darkslategray;
  margin-bottom: auto;
  cursor: pointer;
}

.ref {
  color: white;
  background-color: grey;
  padding: 2px;
  font-size: 10px;
  position: absolute;
  left: 5px;
  top: 5px;
}

.filter_container {
  max-width: 200px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.filter_tip {
  padding: 6px;
  border: 1px solid #302f2f;
  border-radius: 5px;
  width: fit-content;
  text-transform: uppercase;
  background-color: white;
  cursor: pointer;
}

.details_container {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  background-color: white;
  margin: 20px auto 0 auto;
}

.ad_container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  gap: 20px;
}

.details_text {
  width: 280px;
}

.arrow {
  font-size: 40px;
  line-height: 60px;
  vertical-align: middle;
  font-weight: bold;
  color: white;
  padding-bottom: 5px;
  background-color: rgb(0, 0, 0, 0.4);
  position: absolute;
  top: 42%;
  cursor: pointer;
}

.arr_left {
  left: 0px;
}

.arr_right {
  right: 0px;
}

.all_photos {
  display: flex;
  flex-wrap: wrap;

  gap: 3px;
  margin-top: 3px;
}

.small_photo {
  min-width: 90px;
  max-width: 90px;
  aspect-ratio: 4/3;
}

.desc_details {
  margin-top: 15px;
  font-weight: light;
  font-size: 14px;
  white-space: pre-line;
  width: 100%;
  color: #505050;
}

.price {
  font-size: 14px;
  margin-top: 10px;
}

.details_button {
  padding: 8px 12px;
  text-align: center;
  background-color: white;
  width: 100%;
  border: 2px solid darkslategray;
  margin-top: 10px;
  cursor: pointer;
}

.price_button {
  background-color: rgb(96, 126, 126);
  color: white;
  font-weight: bold;
}

.contact_button {
}

@media screen and (max-width: 600px) {
  .gallery_container {
    width: 100%;
  }

  .text {
    width: 100%;
  }

  .desc {
    max-height: 50px;
    max-width: 100%;
  }

  .ad_button {
    float: right;
  }

  .ad_container {
    padding-left: 0;
    padding-right: 0;
  }
  .gallery_details {
    min-width: 200px;
  }

  .all_photos {
    display: none;
  }

  .details_text {
    width: 100%;
    margin: 0 10px;
  }
}
