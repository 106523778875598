.comment {
  border: 1px solid lightgrey;
  padding: 5px;
  border-radius: 5px;
}

.text {
  color: grey;
  font-size: 13px;
}

.owner {
  color: darkblue;
  margin-right: 5px;
  font-weight: lighter;
  font-size: 13px;
}

.date {
  color: darkred;
  margin-right: 5px;
  font-weight: lighter;
  font-size: 13px;
}
