body {
  font-size: 16px;
  font-weight: lighter;
}

.lead_screen {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.lead_list {
  flex-direction: column;
  border: 0px dotted grey;
  gap: 10px;
  padding: 10px;
  align-items: center;
  flex-basis: 100%;
}

.stepper {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.block {
  border: 1px dotted grey;
  padding: 10px;
  font-size: 16px;
  font-weight: lighter;
  position: relative;
  word-wrap: break-word;
  min-height: 60px;
}

.edit_icon {
  position: absolute;
  bottom: 3px;
  right: 5px;
  color: blue;
  cursor: pointer;
}

.container {
  position: relative;
  font-size: 20px;
  font-family: sans-serif;
  width: 300px;
}
.input {
  border: 1px solid #ddd;
  background-color: #fff;
  width: 90%;
  font-size: 16px;
  padding: 5px;
  outline: none;
}
.typeahead {
  color: #a3b4a9;
}
.listbox {
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 5px 10px;
  background-color: #fff;
  width: 100%;
}
.groupHeading {
  border-bottom: 1px solid #ddd;
  margin: 5px 0;
  padding: 5px;
  font-weight: bold;
  font-size: 16px;
  color: #005a1c;
}
.item {
  cursor: pointer;
  padding: 5px;
}
.highlightedItem {
  cursor: pointer;
  background-color: #deebe2;
  padding: 5px;
}

.create_lead_card {
  padding: 7px;
  min-width: fit-content;
  height: fit-content;
  font-size: 0.8rem;
  border: 1px dotted;
  cursor: pointer;
}

.create_lead_empty_card {
  border-color: white;
}

.create_lead_button {
  color: darkblue;
  font-weight: normal;
  cursor: pointer;
}

.create_lead_row {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  max-width: 600px;
  max-height: 50px;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .lead_screen {
    flex-direction: column;
  }
  .stepper {
    display: block;
  }
  .lead_blocks {
    min-width: 100%;
  }
}
