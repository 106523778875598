/* .loader {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

.bounce1,
.bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgb(80, 0, 0);
  opacity: 0.9;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 2s infinite ease-in-out;
}

.bounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
} */

.lds {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 100px auto;
}
.lds div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgb(165, 165, 165);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds div:nth-child(1) {
  left: 8px;
  animation: lds1 0.6s infinite;
}
.lds div:nth-child(2) {
  left: 8px;
  animation: lds2 0.6s infinite;
}
.lds div:nth-child(3) {
  left: 32px;
  animation: lds2 0.6s infinite;
}
.lds div:nth-child(4) {
  left: 56px;
  animation: lds3 0.6s infinite;
}
@keyframes lds1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
