.header {
  /* background-color: #01579b; */
  /* background-color: #212A33; */
  padding: 8px;
  color: white;
  border-radius: 5px 5px 0 0;
  font-size: 14px;
  text-align: center;
}

.info {
  padding: 4px 4px 0 8px;
  font-size: 11px;
  height: 11px;
  font-weight: lighter;
}

.status {
  padding: 4px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
}

.model {
  border: 2px solid rgb(95, 95, 95);
  color: rgb(95, 95, 95);
  background-color: rgb(223, 223, 223);
  border-radius: 10px;
  width: 40px;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.model_desc {
  margin: 8px;
  font-weight: normal;
}

.name {
  font-size: 12px;
  font-weight: normal;
}

.seller {
  border: 2px solid rgb(110, 105, 105);
  color: rgb(95, 95, 95);
  background-color: rgb(195, 206, 255);
  border-radius: 20px;
  width: 20px;
  padding: 3px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
}

.offers {
  border: 2px solid rgb(95, 95, 95);
  color: rgb(95, 95, 95);
  background-color: rgb(255, 199, 126);
  border-radius: 20px;
  width: 20px;
  padding: 3px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
}

.quotes {
  border: 2px solid rgb(95, 95, 95);
  color: rgb(95, 95, 95);
  background-color: #f9f9f9;
  border-radius: 20px;
  width: 25px;
  padding: 3px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
}

.config {
  font-size: 11px;
}

.noconf {
  color: darkred;
}

.prod_status {
  text-transform: uppercase;
  font-size: 10px;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 5px;
}
